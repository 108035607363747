import React from 'react'

import {uiLocations} from '../../common/constants'

import ArrowLink from '../ArrowLink'
import Link from '../Link'

import styles from './Globe.less'
import Background from '../Background'

import Pin from './Pin'

export default () => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.GlobeWrapper}>
        <Background
          className={styles.BackgroundVideo}
          video={'https://misc.boilerroom.tv/globe.mp4'}
          autoplay
          muted
        />
        <div className={styles.Gradient} />
      </div>
      <div className={styles.HeaderWrapper}>
        <img
          src={require('./connect_club_culture.svg')}
          className={styles.BRLargeText}
          alt={'Boiler Room - Connecting Club Culture To The World'}
        />
        <div className={styles.CTAWrapper}>
          <Link
            className={styles.FindAnEventButton}
            internalLink={'/upcoming'}
            uiLocation={uiLocations.globeHeroPanelCta}
          >
            <Pin />
            FIND AN EVENT
          </Link>
          <ArrowLink
            className={styles.BrowseTheArchiveLink}
            text={'Browse the archive'}
            link={'/video'}
            uiLocation={uiLocations.globeHeroPanelCta}
          />
        </div>
      </div>
    </div>
  )
}
