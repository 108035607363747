import {hasWindow} from '../src/common/constants'

export function determineDefaultHomepage(hostname) {
  // server side we should always pass in the hostname.
  // client side we can just get it from window.
  hostname = hostname || (hasWindow && window.location.hostname) || ''
  switch (hostname.split('.')[0]) {
    case 'truemusic':
      return 12
    case 'ukg':
      return 14
    case 'sugarmountain':
      return 15
    case 'eristoff':
    case 'intothedark':
      return 17
    case 'fourthree':
      return 18
    case 'dekmantel':
      return 20
    case 'system':
      return 21
    case 'lowheat':
      return 22
    case 'gasworks':
      return 23
    case 'contemporaryscenes':
      return 24
    case 'harddance':
      return 26
    case 'budx':
      return 27
    case 'festival':
      return 31
    case 'systemrestart':
      return 30
    case 'possession-staging':
      return 34
    case 'worldtour':
      return 36
    case 'energy':
      return 37
    case 'broadcastlab':
      return 38
    case 'budweiser':
      return 39
    case 'boilerroomx':
      return 40
    case 'br15':
      return 42
    case 'editorial':
      return 43
    default:
      return 11
  }
}
